<template>
    <!--begin::Dashboard-->
    <div class="card card-custom gutter-b col-lg-13 col-xxl-12 mt-5 py-5 px-10">
        <!-- begin::Details lesson-->
        <div>
            <div class="d-flex flex-row justify-content-between mb-5 align-items-center">
                <router-link :to="{ name: 'classes/edit', params: { id: lesson.classe_id } }" replace>
                    <span class="btn btn-outline-danger btn-md">
                        <span class="svg-icon svg-icon-md svg-icon-light-danger">
                            <inline-svg src="/media/svg/icons/Navigation/Arrow-left.svg"></inline-svg>
                        </span>
                        Retour à la classe
                    </span>
                </router-link>
                <div class="d-flex flex-row align-items-center">
                    <span class="btn btn-md btn-primary mr-3" hidden>Modifier</span>
                    <span class="btn btn-sm btn-danger" hidden>Supprimer</span>
                </div>
            </div>
            <div class="d-flex flex-column px-5 mb-5 justify-content-between">
                <div class="d-flex flex-row justify-content-between">
                    <span> Titre du cours : {{ lesson.title }} </span>
                    <span> Parcours ordonné : {{ lesson.linear ? 'Oui' : 'Non' }} </span>
                    <div class="d-flex flex-column justify-content-between">
                        <span class="text-hover-primary mb-1"> Créé le : {{ formatDate(lesson.created_at) }} </span>
                        <span class="text-hover-primary mb-1" v-if="this.lesson.updated_at != null">
                            Mis à jour le : {{ formatDate(lesson.updated_at) }}
                        </span>
                    </div>
                </div>
                <div class="d-flex flex-row justify-content-around">
                    <span> Description : {{ lesson.description }} </span>
                </div>
            </div>
        </div>
        <!-- end::Details lesson-->
        <!-- begin::Lesson Items-->
        <div>
            <b-table-simple hover small caption-top responsive>
                <caption>
                    Élements du cours
                </caption>
                <b-thead>
                    <b-tr>
                        <b-th> Ordre </b-th>
                        <b-td> Type </b-td>
                        <b-td> Date de création </b-td>
                        <b-td> Date de mise à jour </b-td>
                        <b-td> Id questionnaire </b-td>
                        <b-td> Id question </b-td>
                        <b-td> Id media </b-td>
                        <b-th hidden><b-button variant="primary">Voir</b-button></b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="item in lesson.items" :key="item">
                        <b-th> {{ item.order }} </b-th>
                        <b-td> {{ item.media_id ? 'Media' : 'QCM' }} </b-td>
                        <b-td> {{ item.created_at }} </b-td>
                        <b-td> {{ item.updated_at }} </b-td>
                        <b-td> {{ item.survey_id }} </b-td>
                        <b-td> {{ item.survey_item_id }} </b-td>
                        <b-td> {{ item.media_id }} </b-td>
                        <b-th hidden><b-button variant="primary">Voir</b-button></b-th>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </div>
        <div v-if="lesson.items == ''">
            <div class="my-5 d-flex flex-column align-items-center">
                <p class="bg-secondary btn-block rounded py-5 text-center" disabled>
                    <i class="text-dark">Le cours ne possède pas d'élément</i>
                </p>
            </div>
        </div>
        <router-link :to="{ name: 'lessonItems/editor', params: { id: lesson.id } }" replace>
            <span class="btn btn-primary btn-lg">
                Accèder à la composition du cours
            </span>
        </router-link>
        <!-- end::Lesson Items-->
    </div>
    <!--end::Dashboard-->
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import { LIST_LESSON } from '@/core/services/store/api/lesson.service';

import { mapGetters } from 'vuex';
//import ApiService from "@/core/services/api.service";
import i18n from '@/core/plugins/vue-i18n.js';
import moment from 'moment';

export default {
    name: 'admin-edit-lesson',
    components: {},
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [{ title: i18n.t('MENU.DASHBOARD') }]);
        if (this.$route.params.id) {
            this.$store.dispatch(LIST_LESSON, this.$route.params.id);
        } else {
            this.$router.push({ name: 'admin/dashboard' });
        }
    },
    computed: {
        ...mapGetters({ lesson: 'getLesson' })
    },
    data() {
        return {
            show: true
        };
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(String(value)).format('DD/MM/YYYY');
            }
        }
        /*
		onSubmit(evt) {
			evt.preventDefault()
			alert(JSON.stringify(this.classe))
		},
		onReset() {
			this.editForm.miniature = this.classeTest.miniature;
			this.editForm.title = this.classeTest.title;
			this.editForm.description = this.classeTest.description;
			this.editForm.text_certificate = this.classeTest.text_certificate;
			this.editForm.goal = this.classeTest.goal;
			this.editForm.linear = this.classeTest.linear;
		},
		onCancel() {
			this.edit = false
		},
		UpdateClasse(){
			this.editForm.user_id = this.$store.getters.currentUser.id;
			this.editForm.id = this.classeTest.id;
			
			ApiService.post(process.env.VUE_APP_API_URL+"/academy/classes/update", this.editForm).then((result) => { 
				if(result.data){
					this.ReloadClasse();
				}
			}).catch(error => {
				this.errorMessage = error.message;
			});
		},
		ReloadClasse(){
			if(this.edit == true){
				this.$store.dispatch(LIST_CLASSE, this.$route.params.id).then(()=>{
					this.edit = false;
					this.$forceUpdate();
				});
			}
		},
		DeleteClasse(){
			ApiService.get(process.env.VUE_APP_API_URL, "academy/classes/delete/" + this.classeTest.id).then((result) => { 
				if(result.data){
					this.$router.push({ name: 'admin/dashboard' });
				}
			}).catch(error => {
				this.errorMessage = error.message;
			});
		}
		*/
    }
};
</script>
